module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-11425486-39","anonymize":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mosaic Corp Employee Satisfaction Survey","short_name":"Mosaic Corp","description":"Your opinion is very important to us. The following series of questions will help us better understand your experience here at Mosaic Corp.","lang":"en","start_url":"/","display":"minimal-ui","background_color":"#efdecb","theme_color":"#005472","icon":"static/meta-images/icon.png","cache_busting_mode":"name","include_favicon":false},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
