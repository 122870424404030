import React from 'react'
import styled from 'styled-components'

const LinesEl = styled.div.attrs({className: 'lines-container'})`
`

const Line = styled.div.attrs({className: 'line'})`
`

const Lines = () => (
  <LinesEl>
    <Line />
    <Line />
    <Line />
    <Line />
    <Line />
  </LinesEl>
)

export default Lines
