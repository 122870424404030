import React from "react"
import PropTypes from "prop-types"

const StoreContext = React.createContext()
const StoreConsumer = StoreContext.Consumer

class StoreProvider extends React.Component {
  state = {
    currentQuestion: 0,
  }

  render() {
    const { currentQuestion } = this.state

    const { setCurrentQuestion } = this

    return (
      <StoreContext.Provider
        value={{
          currentQuestion,
          setCurrentQuestion,
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    )
  }

  setCurrentQuestion = val => {
    this.setState({ currentQuestion: val })
  }
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { StoreContext, StoreProvider, StoreConsumer }
