import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import { rem } from "polished"

// import '../config/yup'

import SEO from "../components/seo"
import Link from "../components/link"
import Lines from "../components/lines"

import { StoreProvider, StoreConsumer } from "../store"
import theme from "../theme"

import "../assets/stylesheets/app/app.scss"

const Main = styled.main.attrs({className: 'layout-main'})`
  padding: 0 ${rem(40)};
  background: ${props => props.theme.colorGrey};

  @media ${props => props.theme.mediumDown} {
    padding: 0 ${rem(20)};
  }
`

const Footer = styled.footer`
  margin-bottom: ${rem(60)};
  text-transform: uppercase;
  text-align: center;
  font-family: ${props => props.theme.ffTertiary};
  font-weight: 300;
  font-size: ${rem(14)};
  letter-spacing: ${rem(1)};

  a:hover {
    color: #42b6df;
  }
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <StoreProvider>
      <SEO />

      <StoreConsumer>
        {({ currentQuestion }) => (
          <Main
            className={`layout-status-question-${currentQuestion}`}
            currentQuestion={currentQuestion}
          >
            <Lines />

            {children}

            <Footer>
              <Link to="https://www.mosaiccorp.biz">Mosaic</Link>
            </Footer>
          </Main>
        )}
      </StoreConsumer>

    </StoreProvider>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
